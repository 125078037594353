/**
 * Product page click on rating count then scroll to the comments
 * DEPRECATED : need to find a way to make it work with boostrap tab "shown.bs.tab" evant
 */
// (function ($) {
//   "use strict";

//   $(function () {
//     if ($("#rating-btn").length > 0) {
//       const btn = $("#rating-btn");
//       const btn2 = $("#reviews-tab");
//       const div = $("#reviews2");
//       const div2 = $("#myTab");

//       btn.click(function () {
//         div.addClass("active show").siblings().removeClass("active show");
//         btn2.addClass("active");

//         btn2
//           .parent()
//           .siblings()
//           .each(function () {
//             $(this).children().removeClass("active");
//           });
//         scrollToAnchor(this, div);
//       });
//     }
//   });

//   function scrollToAnchor(id, target) {
//     var btn = $("btn[id='" + id + "']");
//     $("html,body").animate({ scrollTop: target.offset().top - 200 }, "fast");
//   }
// })(jQuery);
