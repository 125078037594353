if (document.getElementById("activities-filter")) {
  let buttons = document.querySelectorAll(".filter-btn");
  let posts = document.querySelectorAll(".post-item");

  buttons.forEach((button) => {
    button.addEventListener("click", () => {
      const targetClass = button.dataset.target;

      // Si le bouton a déjà la classe "active", afficher tous les posts et retirer la classe "active"
      if (button.classList.contains("active")) {
        posts.forEach((post) => {
          post.style.display = "block";
        });
        button.classList.remove("active");
      } else {
        // Si le bouton n'a pas la classe "active", mettre à jour les posts en fonction de la classe cible
        buttons.forEach((btn) => {
          if (btn === button) {
            btn.classList.add("active");
          } else {
            btn.classList.remove("active");
          }
        });

        posts.forEach((post) => {
          if (post.classList.contains(targetClass)) {
            post.style.display = "block";
          } else {
            post.style.display = "none";
          }
        });
      }
    });
  });
}
