//THEME
import defaults from "./custom/defaults.js";
import navbar from "./custom/navbar.js";
import footer from "./custom/footer.js";
// import parallax from "./custom/parallax.js";
import anchorNav from "./custom/anchorNav.js";
// import avatar from "./custom/avatar.js";
// import megaMenu from "./custom/megaMenu.js";
import filter from "./custom/activities-filter";
//Woocommerce
import cart from "./custom/woocommerce/cart.js";
import product from "./custom/woocommerce/product.js";
import checkout from "./custom/woocommerce/checkout.js";
import single_product from "./custom/woocommerce/single-product.js";
//EXTRAS
import vhb from "./custom/vhb.js";
//import cards from './custom/cards.js';
//import progressBar from './custom/progressBar.js';
//import annimate from './custom/annimate.js';

//VueJS
//import footer from './custom/vue-scripts.js';
