/**
 * Woocommerce
 * Open / close checkout sections process
 */
(function ($) {
  "use strict";

  $(function () {
    if ($("#checkout-form-js").length > 0) {
      console.log("checkout");
      const btn01 = $("#btn01");
      const btn02 = $("#btn02");
      const step01 = $("#shipping-info");
      const step02 = $("#order-review");
      const step03 = $("#payment");
      const step02Section = $("#step02-section");
      const step03Section = $("#step03-section");

      btn01.click(function () {
        btn01.addClass("collpased");
        btn02.removeClass("collapsed");
        step01.removeClass("show");
        step02.addClass("show");
        step03.removeClass("show");
        scrollToAnchor(this, step02Section);
      });

      btn02.click(function () {
        btn02.addClass("collapsed");
        btn01.removeClass("collapsed");
        step01.removeClass("show");
        step02.removeClass("show");
        step03.addClass("show");
        scrollToAnchor(this, step03Section);
      });
    }
  });

  function scrollToAnchor(id, target) {
    var btn = $("btn[id='" + id + "']");
    $("html,body").animate({ scrollTop: target.offset().top - 100 }, "fast");
  }
})(jQuery);
