/**
 *
 * SHOW / HIDE floating cart on every page
 *
 *
 */
(function ($) {
  "use strict";
  $(function () {
    // MainNav
    $("#open-cart").on({
      click: function () {
        if ($("#menupanel-cart").css("display") == "none") {
          $("#menupanel-cart").css("display", "block");
          $(".mobile-toggle-cta").addClass("collapsed");
          //$('#main_nav').removeClass('show');
          document.body.style.position = "fixed";
          document.body.style.top = `-${window.scrollY}px`;
          document.body.style.position = "";
          document.body.style.top = "";
        } else {
          $("#menupanel-cart").css("display", "none");

          const scrollY = document.body.style.top;
          document.body.style.position = "";
          document.body.style.top = "";
          window.scrollTo(0, parseInt(scrollY || "0") * -1);
        }
      },
    });
    closecart;
    $("#closecart").on({
      click: function () {
        $("#menupanel-cart").css("display", "none");
      },
    });

    //Close mainMenu when click outside of the box
    $("body").on("click", function (e) {
      if ($(e.target).closest("#open-cart").length == 0) {
        $("#menupanel-cart").css("display", "none");
      }
    });
  });
})(jQuery);
